import ant_de from 'antd/lib/locale-provider/de_DE'
import { flattenPayload } from 'app/lib/utils/flatten-object'

import notFoundMessages from '../../app/pages/404/Translations/de_DE.json'
import availabilityRequestMessages from '../../app/pages/AvailabilityRequest/Translations/de_DE.json'
import dashboardMessages from '../../app/pages/Dashboard/Translations/de_DE.json'
import faqMessages from '../../app/pages/Faq/Translations/de_DE.json'
import finalizedRentalRequestMessages from '../../app/pages/FinalizeRentalRequest/Translations/de_DE.json'
import fleetReviewMessages from '../../app/pages/FleetReview/Translations/de_DE.json'
import loginMessages from '../../app/pages/Login/Translations/de_DE.json'
import rentalShowMessages from '../../app/pages/OrderRequest/Translations/de_DE.json'
import purchasingRequestMessages from '../../app/pages/PurchasingRequest/Translations/de_DE.json'
import rentalParkMessages from '../../app/pages/RentalPark/Translations/de_DE.json'
import resetPasswordMessages from '../../app/pages/ResetPassword/Translations/de_DE.json'
import rolesMessages from '../../app/pages/Roles/Translations/de_DE.json'
import usersMessages from '../../app/pages/Users/Translations/de_DE.json'
import deMessages from '../locales/de_DE.json'
import deV2Messages from '../locales/v2/de_DE.json'

const deTranslationModules = [
  deMessages,
  flattenPayload(deV2Messages),
  availabilityRequestMessages,
  finalizedRentalRequestMessages,
  usersMessages,
  rolesMessages,
  rentalShowMessages,
  dashboardMessages,
  fleetReviewMessages,
  loginMessages,
  resetPasswordMessages,
  notFoundMessages,
  faqMessages,
  rentalParkMessages,
  purchasingRequestMessages
]

const deTranslationModulesKeys = deTranslationModules.flatMap((module) => Object.keys(module))

if (new Set(deTranslationModulesKeys).size !== deTranslationModulesKeys.length) {
  console.error(
    deTranslationModulesKeys.filter(
      (item, index) => deTranslationModulesKeys.indexOf(item) !== index
    )
  )
  throw Error('Duplicated keys for translation')
}
const combinedMessages = {
  ...Object.assign({}, ...deTranslationModules)
}

const deDE = {
  ...ant_de,
  Text: {
    copy: 'Kopieren',
    copied: 'Kopiert'
  }
}

const typeTemplate = 'Bitte geben Sie eine gültige ein ${type}'

const deLang = {
  messages: {
    ...combinedMessages,
    formValidation: {
      required: 'Dieses Feld ist erforderlich',
      types: {
        string: typeTemplate,
        method: typeTemplate,
        array: typeTemplate,
        object: typeTemplate,
        number: typeTemplate,
        date: 'Bitte geben Sie eine gültiges Datum ein',
        boolean: typeTemplate,
        integer: typeTemplate,
        float: typeTemplate,
        regexp: typeTemplate,
        email: 'Bitte geben Sie eine gültige E-Mail Adresse ein',
        url: typeTemplate,
        hex: typeTemplate
      }
    }
  },
  antLocale: deDE,
  locale: 'de-DE'
}

export default deLang
