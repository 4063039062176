import PageLoading from 'app/components/UI-Elements/Feedback/loading/PageLoading'
import PageWrapper from 'app/components/UI-Elements/Layout/PageWrapper'
import { Routes } from 'app/constants/Routes'
import ErrorNotFound from 'app/pages/404/ErrorNotFound'
import Layout from 'app/pages/AvailabilityRequest/Containers/Layout'
import PurchasingRequestResponse from 'app/pages/PurchasingRequest/Views/response'
import PurchasingRequestShowPage from 'app/pages/PurchasingRequest/Views/show'
import { getBaseRoutePath, retry } from 'app/util/Utilities'
import * as React from 'react'
import { lazy, Suspense } from 'react'
import { Route, Routes as Switch } from 'react-router-dom'

const Request = lazy(() => retry(() => import('../../pages/AvailabilityRequest/Pages/Request')))
const Finalize = lazy(() => retry(() => import('../../pages/FinalizeRentalRequest/Pages/Finalize')))
const Overview = lazy(() => retry(() => import('../../pages/AvailabilityRequest/Pages/Overview')))
const Success = lazy(() => retry(() => import('../../pages/AvailabilityRequest/Pages/Success')))

const OrderRequestShowPage = lazy(() => retry(() => import('app/pages/OrderRequest/Views/show')))
const OrderRequestsPage = lazy(() => retry(() => import('app/pages/OrderRequest/Views')))

const RentalRequestRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        key="finalize-legacy"
        path={Routes.OrderRequest.member(undefined, true, false).finalize}
        element={
          <Suspense fallback={<PageLoading />}>
            <Finalize />
          </Suspense>
        }
      />
      <Route
        key="finalize"
        path={Routes.OrderRequest.member(undefined, false, false).finalize}
        element={
          <Suspense fallback={<PageLoading />}>
            <Finalize />
          </Suspense>
        }
      />
      <Route
        key="availability-legacy"
        path={Routes.OrderRequest.member(undefined, true, false).availability}
        element={
          <Suspense fallback={<PageLoading />}>
            <Layout>
              <Request />
            </Layout>
          </Suspense>
        }
      />
      <Route
        key="availability"
        path={Routes.OrderRequest.member(undefined, false, false).availability}
        element={
          <Suspense fallback={<PageLoading />}>
            <Layout>
              <Request />
            </Layout>
          </Suspense>
        }
      />
      <Route
        key="availability-confirmation-legacy"
        path={Routes.OrderRequest.member(undefined, true, false).availability_confirmation}
        element={
          <Suspense fallback={<PageLoading />}>
            <Layout>
              <Success />
            </Layout>
          </Suspense>
        }
      />
      <Route
        key="availability-confirmation"
        path={Routes.OrderRequest.member(undefined, false, false).availability_confirmation}
        element={
          <Suspense fallback={<PageLoading />}>
            <Layout>
              <Success />
            </Layout>
          </Suspense>
        }
      />
      <Route
        key="overview-legacy"
        path={Routes.OrderRequest.member(undefined, true, false).overview}
        element={
          <Suspense fallback={<PageLoading />}>
            <Layout>
              <Overview />
            </Layout>
          </Suspense>
        }
      />
      <Route
        key="overview"
        path={Routes.OrderRequest.member(undefined, false, false).overview}
        element={
          <Suspense fallback={<PageLoading />}>
            <Layout>
              <Overview />
            </Layout>
          </Suspense>
        }
      />
      <Route
        key="purchasing-request-response"
        path={Routes.PurchasingRequest.member(undefined, false).response}
        element={
          <Suspense fallback={<PageLoading />}>
            <PurchasingRequestResponse />
          </Suspense>
        }
      />
      <Route
        path={Routes.OrderRequest.member(undefined, true, false).show}
        element={
          <Suspense fallback={null}>
            <OrderRequestShowPage />
          </Suspense>
        }
        key="order-request-show"
      />
      <Route
        path={Routes.OrderRequest.member(undefined, false, false).show}
        element={
          <Suspense fallback={null}>
            <PurchasingRequestShowPage />
          </Suspense>
        }
        key="purchasing-request-show"
      />
      <Route
        path={'/'}
        element={
          <Suspense fallback={null}>
            <PageWrapper>
              <OrderRequestsPage />
            </PageWrapper>
          </Suspense>
        }
        key="order-request-index-page"
      />
      <Route path={getBaseRoutePath('')} element={<ErrorNotFound />} />
    </Switch>
  )
}

export default RentalRequestRoutes
