import enUS from 'antd/lib/locale-provider/en_US'
import { flattenPayload } from 'app/lib/utils/flatten-object'

import notFoundMessages from '../../app/pages/404/Translations/en_US.json'
import availabilityRequestMessages from '../../app/pages/AvailabilityRequest/Translations/en_US.json'
import dashboardMessages from '../../app/pages/Dashboard/Translations/en_US.json'
import faqMessages from '../../app/pages/Faq/Translations/en_US.json'
import finalizedRentalRequestMessages from '../../app/pages/FinalizeRentalRequest/Translations/en_US.json'
import fleetReviewMessages from '../../app/pages/FleetReview/Translations/en_US.json'
import loginMessages from '../../app/pages/Login/Translations/en_US.json'
import rentalShowMessages from '../../app/pages/OrderRequest/Translations/en_US.json'
import purchasingRequestMessages from '../../app/pages/PurchasingRequest/Translations/en_US.json'
import rentalParkMessages from '../../app/pages/RentalPark/Translations/en_US.json'
import resetPasswordMessages from '../../app/pages/ResetPassword/Translations/en_US.json'
import rolesMessages from '../../app/pages/Roles/Translations/en_US.json'
import usersMessages from '../../app/pages/Users/Translations/en_US.json'
import enMessages from '../locales/en_US.json'
import enV2Messages from '../locales/v2/en_US.json'

const enTranslationModules = [
  enMessages,
  flattenPayload(enV2Messages),
  availabilityRequestMessages,
  finalizedRentalRequestMessages,
  usersMessages,
  rolesMessages,
  rentalShowMessages,
  dashboardMessages,
  fleetReviewMessages,
  loginMessages,
  resetPasswordMessages,
  notFoundMessages,
  faqMessages,
  rentalParkMessages,
  purchasingRequestMessages
]

const enTranslationModulesKeys = enTranslationModules.flatMap((module) => Object.keys(module))

if (new Set(enTranslationModulesKeys).size !== enTranslationModulesKeys.length) {
  console.error(
    enTranslationModulesKeys.filter(
      (item, index) => enTranslationModulesKeys.indexOf(item) !== index
    )
  )
  throw Error('Duplicated keys for translation')
}

const combinedMessages = {
  ...Object.assign({}, ...enTranslationModules)
}

const typeTemplate = 'Please put a valid ${type}'

const enLang = {
  messages: {
    ...combinedMessages,
    formValidation: {
      required: 'This field is required.',
      types: {
        string: typeTemplate,
        method: typeTemplate,
        array: typeTemplate,
        object: typeTemplate,
        number: typeTemplate,
        date: typeTemplate,
        boolean: typeTemplate,
        integer: typeTemplate,
        float: typeTemplate,
        regexp: typeTemplate,
        email: typeTemplate,
        url: typeTemplate,
        hex: typeTemplate
      }
    }
  },
  antLocale: enUS,
  locale: 'en-US'
}

export default enLang
